import './Product.less';
import vsf, { definePage } from '@vs/vsf-boot';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';

import {
  Section,
  Form,
  Input,
  Button,
  TextArea,
  Row,
  Col,
  Select,
  message,
} from '@vs/vsf-kit';

import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const Product = (props) => {
  const [form] = Form.useForm();

  const selectOptions = [
    {
      label: '购买',
      value: 'BUY',
    },
    {
      label: '售后',
      value: 'SALES',
    },
    {
      label: '合作',
      value: 'COOPERATE',
    },
  ];

  const selectOptionsEn = [
    {
      label: 'Purchase product',
      value: 'BUY',
    },
    {
      label: 'After-sales',
      value: 'SALES',
    },
    {
      label: 'Become a Distributor',
      value: 'COOPERATE',
    },
  ];

  const handelSelect = (e) => {
    console.debug(e, '==e==');
  };

  const onFinish = (values) => {
    vsf?.services
      ?.MessageController_createMessage_4501e0?.({
        btoParam: values,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          message.success('提交成功！');
          form?.resetFields();
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [bitmapImg, setBitmapImg] = useState(getImages('Bitmap'));
  const [bitmap1Img, setBitmap1Img] = useState(getImages('Bitmap1'));
  const [bitmap2Img, setBitmap2Img] = useState(getImages('Bitmap2'));
  const [bitmap3Img, setBitmap3Img] = useState(getImages('Bitmap3'));
  return (
    <Section>
      <div className="productContainer">
        <div className="productContainer-titleImg">
          <img src={getImages('productTItile')} alt="" />
        </div>

        <div className="productContainer-content">
          <div className="productContainer-content__title">
            <img
              src={home}
              alt=""
              className="productContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="productContainer-content__title__group"
            />
            <div className="productContainer-content__title__text">
              {getLabel('chanpin')}
            </div>
          </div>
          <div className="productContainer-content__center">
            <div className="productContainer-content__center__left">
              <div className="productContainer-content__center__left__img">
                <img
                  src={bitmapImg}
                  alt=""
                  loading="lazy"
                  onMouseEnter={() => {
                    setBitmapImg(getImages('BitmapScale'));
                  }}
                  onMouseLeave={() => {
                    setBitmapImg(getImages('Bitmap'));
                  }}
                  className="productContainer-content__center__left__top"
                  onClick={() => {
                    vsf?.navigateTo('./photovoltaic');
                  }}
                />
              </div>
              <div className="productContainer-content__center__left__bottom">
                <div className="productContainer-content__center__left__bottom__Left">
                  <img
                    src={bitmap1Img}
                    alt=""
                    loading="lazy"
                    onMouseEnter={() => {
                      setBitmap1Img(getImages('Bitmap1Scale'));
                    }}
                    onMouseLeave={() => {
                      setBitmap1Img(getImages('Bitmap1'));
                    }}
                    onClick={() => {
                      vsf?.navigateTo('./productDetail?type=powerPlant');
                    }}
                  />
                </div>

                <div className="productContainer-content__center__left__bottom__Right">
                  <img
                    src={bitmap2Img}
                    alt=""
                    loading="lazy"
                    onMouseEnter={() => {
                      setBitmap2Img(getImages('Bitmap2Scale'));
                    }}
                    onMouseLeave={() => {
                      setBitmap2Img(getImages('Bitmap2'));
                    }}
                    onClick={() => {
                      vsf?.navigateTo('./productDetail?type=microgrid');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="productContainer-content__center__right">
              <img
                src={bitmap3Img}
                alt=""
                loading="lazy"
                onMouseEnter={() => {
                  setBitmap3Img(getImages('Bitmap3Scale'));
                }}
                onMouseLeave={() => {
                  setBitmap3Img(getImages('Bitmap3'));
                }}
                className="productContainer-content__center__right__img"
                onClick={() => {
                  vsf?.navigateTo('./productDetail?type=robot');
                }}
              />
            </div>
          </div>
        </div>

        <div className="ConnectUsContainer-bottom">
          <div className="ConnectUsContainer-bottom__left">
            <div className="ConnectUsContainer-bottom__left__title">
              Contact Us
            </div>
            <div className="ConnectUsContainer-bottom__left__text">
              {getLabel('zaixianliuyan')}
            </div>
            <div className="ConnectUsContainer-bottom__left__content">
              {getLabel('womenzhili')}
            </div>
          </div>
          <div className="ConnectUsContainer-bottom__right">
            <Form name="basic" form={form} onFinish={onFinish}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入公司名称!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the company name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('gongsiming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="mail"
                    rules={
                      isChinese()
                        ? [
                            { required: true, message: '请输入邮箱!' },
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                              ),
                              message: '请检查邮箱格式',
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: 'Please enter your email!',
                            },
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                              ),
                              message: 'Please check the email format!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('youxiang')}
                      type="email"
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入姓名!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter your name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('xingming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    // rules={
                    //   isChinese()
                    //     ? [{ required: true, message: '请输入电话!' }]
                    //     : [
                    //         {
                    //           required: true,
                    //           message: 'Please enter phone number!',
                    //         },
                    //       ]
                    // }
                  >
                    <Input
                      placeholder={getLabel('shouji')}
                      className="customInput"
                      maxLength={11}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="address"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入地址!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the address!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('dizhi3')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="messageType"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请选择类型!' }]
                        : [
                            {
                              required: true,
                              message: 'Please select a type!',
                            },
                          ]
                    }
                  >
                    <Select
                      className="customSelect"
                      placeholder={getLabel('leixing')}
                      dataSource={isChinese() ? selectOptions : selectOptionsEn}
                      onChange={handelSelect}
                    />
                  </Form.Item>
                </Col>

                <Col span={36}>
                  <Form.Item
                    name="content"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入留言内容!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the message content!',
                            },
                          ]
                    }
                  >
                    <TextArea
                      placeholder={getLabel('liuyan')}
                      className="customTextArea"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: '3.875rem',
                  }}
                >
                  {getLabel('tijiao')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(Product);
