import './AboutUs.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Carousel } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import company from '@/assets/image/Rectangle182.png';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';
import bottomPhoto from '@/assets/image/bottomPhoto.png';
import aboutUsTitle from '@/assets/image/aboutUsTitle.png';
const AboutUs = (props) => {
  const [swiperList, setSwiperList] = useState([]);
  const scrollDivRef = useRef(null);
  const speed = -3;
  const loadSwiperInfo = () => {
    vsf?.services
      ?.FrontPageController_getAllByAboutUsQto_0487ea?.({
        qto: {
          from: 0,
          size: 1000,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setSwiperList(resposne?.data);
          setTimeout(() => {
            startScrolling();
          }, 1);
        }
      });
  };

  const startScrolling = () => {
    const scrollDiv = document.getElementById('scroll_div');
    const scrollBegin = document.getElementById('scroll_begin');
    const scrollEnd = document.getElementById('scroll_end');
    if (!scrollBegin && !scrollEnd) {
      return;
    }
    scrollEnd.innerHTML = scrollBegin.innerHTML ?? '';
    function marquee(isAdd = true) {
      if (scrollEnd.offsetWidth - scrollDiv.scrollLeft <= 0) {
        scrollDiv.scrollLeft -= scrollBegin.offsetWidth;
      } else {
        if (isAdd) scrollDiv.scrollLeft++;
      }
    }
    var MyMar = setInterval(marquee, speed);

    scrollDiv.onmouseover = function () {
      clearInterval(MyMar);
    };
    scrollDiv.onmouseout = function () {
      MyMar = setInterval(() => marquee(false), speed);
    };
  };

  useEffect(() => {
    loadSwiperInfo();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <div className="AboutUsContainer">
        <div className="AboutUsContainer-titleImg">
          <img src={aboutUsTitle} alt="" />
        </div>

        <div className="AboutUsContainer_introduce">
          <div className="AboutUsContainer_introduce__left">
            <div className="AboutUsContainer_introduce__left__title">
              Our Goodness
            </div>
            <div className="AboutUsContainer_introduce__left__text">
              {getLabel('lingtanweilai2')}
              <br></br>
              {getLabel('quanqiugongxiang')}
            </div>
          </div>
          <div
            className="AboutUsContainer_introduce__right"
            style={
              isChinese() ? { fontSize: '4.6875rem' } : { fontSize: '3rem' }
            }
          >
            {getLabel('ranglvse')}
            <br></br> {getLabel('ranglingtan')}
          </div>
        </div>

        <div
          className="scroll_div"
          id="scroll_div"
          ref={scrollDivRef}
          onMouseOver={() => clearInterval(scrollDivRef.current)}
          onMouseOut={startScrolling}
        >
          <div className="scroll_begin" id="scroll_begin">
            <ul>
              {swiperList?.map((item) => {
                return (
                  <>
                    <li>
                      <img src={JSON.parse(item?.img)} loading="lazy" />
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div id="scroll_end"></div>
        </div>

        <div className="AboutUsContainer_swiperLine">
          <div className="AboutUsContainer_swiperLine__line"></div>
        </div>

        <div className="AboutUsContainer_company">
          <div className="AboutUsContainer_company__left">
            <div className="AboutUsContainer_company__left__title">
              About Us
            </div>
            <div className="AboutUsContainer_company__left__text">
              {isChinese() ? (
                <>
                  桥跃智能<br></br>科技（杭州）有限公司
                </>
              ) : (
                getLabel('qiaoyuezhineng')
              )}
            </div>
            <div style={{ flex: 1 }} />
            <div
              className="AboutUsContainer_company__left__content"
              style={
                isChinese()
                  ? {
                      fontSize: '1.5rem',
                    }
                  : {
                      fontSize: '1.25rem',
                    }
              }
            >
              {getLabel('gongsizizhuan')}
            </div>
          </div>
          <img
            className="AboutUsContainer_company__right"
            src={company}
            loading="lazy"
          ></img>
        </div>

        <div className="AboutUsContainer_bottomLine"></div>

        <div className="AboutUsContainer_bottom">
          <div className="AboutUsContainer_bottomImg">
            <img src={bottomPhoto} alt="" className="" />
          </div>
          <div className="AboutUsContainer_bottom-top">
            <div className="AboutUsContainer_bottom-top_title">
              {getLabel('jiaruqiaoyue')}
            </div>
            <div
              className="AboutUsContainer_bottom-top_footer"
              onClick={() => {
                vsf.navigateTo('./Recruitment');
              }}
            >
              <div className="AboutUsContainer_bottom-top_footer__name">
                {getLabel('jiaruwomen')}
              </div>
              <div className="AboutUsContainer_bottom-top_footer__icon"></div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(AboutUs);
